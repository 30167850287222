:root {
  --primary-text-color: #e5e5e5;
  --second-text-color: #fff;
  --back-color: #5b5b5b;
  --second-back-color: #1f1f1fc4;
  --default-bg-color: #494949;
  --back-gradient: linear-gradient( 324deg, var(--back-color), var(--second-back-color));
  --hover-color: #c66c49;
}

.switcher__radio {
  filter: invert();
}

.switcher__radio:checked {
  filter: invert(0);
}

.switcher__status {
  filter: invert();
}

/*# sourceMappingURL=index.62a338ff.css.map */
